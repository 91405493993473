<template>
  <b-row>
    <b-col md="12">
      <b-row>
        <b-col md="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right mb-1 ml-2"
            onclick="history.back()"
          >
            <feather-icon icon="ArrowLeftCircleIcon" />
            Retour
          </b-button>
          <b-button
            v-if="!enChat"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.validation-finale
            v-b-tooltip.hover.bottom="'Apporter votre avis sur la question'"
            variant="success"
            size="sm"
            :disabled="expertise.statut === 'Fermée'"
            class="float-right mb-1"
            @click="modeChat"
          >
            <span class="badge badge-warning mr-1">{{ items.length }}</span>
            Rejoindre le flux de discussions
          </b-button>
          <b-button
            v-if="enChat"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-tooltip.hover.bottom="'Retournez aux détails de la question'"
            variant="primary"
            size="sm"
            :disabled="expertise.statut === 'Fermée'"
            class="float-right mb-1 mr-1"
            @click="modeChat"
          >
            <feather-icon icon="PlusCircleIcon" />
            Détails de la préoccupation
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="!enChat">
        <b-col md="6">
          <b-row>
            <b-col md="12">
              <b-card no-body>
                <b-card-body>
                  <b-list-group>
                    <b-list-group-item
                      class="d-flex justify-content-between align-items-center"
                      active
                    >
                      <span>Informations sur la demande d'expertise</span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>N° de référence</span>
                      {{ expertise.reference }}
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Statut de la demande</span>
                      <b-badge
                        pill
                        :variant="`${resolveExpertiseStatusVariant(expertise.statut)}`"
                      >
                        {{ expertise.statut }}
                      </b-badge>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Date du dépôt de la demande</span>
                      {{ expertise.created_at }}
                    </b-list-group-item>
                    <b-list-group-item class="d-flex flex-column">
                      <u><span class="font-weight-bold">Objet de la demande:</span></u>
                      <p>{{ expertise.question }}</p>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex flex-column">
                      <span class="font-weight-bold">Description:</span>
                      <p>{{ expertise.description }}</p>
                    </b-list-group-item>
                  </b-list-group>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="12">
              <b-col no-body>
                <b-card>
                  <b-card-body>
                    <b-list-group>
                      <b-list-group-item
                        class="d-flex justify-content-between align-items-center"
                        active
                      >
                        <span>Bilan des réponses:</span>
                      </b-list-group-item>
                      <b-list-group-item
                        v-if="expertise.reponse_expertises && expertise.reponse_expertises.length == 0"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <span>Aucun expert n'a encore répondu à cette préoccupation.</span>
                      </b-list-group-item>
                      <b-list-group-item
                        v-else
                        class="d-flex flex-column"
                      >
                        <b-button
                          v-if="!enChat"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          v-b-modal.validation-finale
                          v-b-tooltip.hover.bottom="'Apporter votre avis sur la question'"
                          variant="success"
                          size="sm"
                          :disabled="expertise.statut === 'Fermée'"
                          class="float-right mb-1"
                          @click="modeChat"
                        >
                          <feather-icon icon="CheckCircleIcon" />
                          Rejoindre le flux de discussions
                        </b-button>
                      </b-list-group-item>
                    </b-list-group>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col md="12">
              <b-card no-body>
                <b-card-body>
                  <b-list-group>
                    <b-list-group-item
                      class="d-flex justify-content-between align-items-center"
                      active
                    >
                      <span>Informations de l'interessé</span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Nom de l'interessé</span>
                      {{ expertise.user.lastname }}

                    </b-list-group-item>

                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Numéro de téléphone</span>
                      {{ expertise.user.telephone }}

                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Adresse e-mail</span>
                      {{ expertise.user.email }}
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Rôle</span>
                      {{ expertise.user.role }}

                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <span>Status du compte</span>
                      {{ expertise.user.status }}

                    </b-list-group-item>
                  </b-list-group>
                </b-card-body>
              </b-card>
            </b-col><b-col md="12">
              <b-card title="Pièces fournies">
                <b-card-body>
                  <b-row class="float-right pb-1">
                    <b-col md="12">
                      <b-button
                        id="toggle-btn"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-prevent-closing
                        variant="outline-primary"
                        size="sm"
                      >
                        Ajouter une pièce
                      </b-button>
                    </b-col>
                  </b-row>
                  <div v-if="submittedNames.length === 0">
                    <b-table
                      :fields="fields1"
                      small
                      :empty-filtered-text="'Aucun résultat n\'a été trouvé'"
                      :empty-text="'Aucun résultat n\'a été trouvé'"
                      show-empty
                      responsive="sm"
                      :items="expertise.piece_expertises"
                    >
                      <template #cell(actions)="data">
                        <feather-icon
                          v-b-modal.modal-prevent-image-closing
                          icon="EyeIcon"
                          @click="getImage(data)"
                        />
                        <feather-icon
                          class="text-danger ml-2  w"
                          icon="TrashIcon"
                          @click="removeItem(data)"
                        />
                      </template>
                    </b-table>
                  </div>
                  <b-list-group v-else>
                    <b-list-group-item
                      v-for="(data, index) in submittedNames"
                      :key="index"
                    >
                      {{ data }}
                    </b-list-group-item>
                  </b-list-group>
                </b-card-body>
              </b-card>
            </b-col>

            <b-modal
              id="modal-prevent-image-closing"
              ref="my-modal"
              button-size="sm"
              size="lg"
              title="Pièce jointe"
              ok-only
              ok-title="Fermer"
            >
              <b-card
                img-top
                class="position-static"
              >
                <iframe
                  :src="imageUrl"
                  style="width: 100%; height: 600px; border: none;"
                >
                  Oops! an error has occurred.
                </iframe>
              </b-card>
            </b-modal>
            <b-modal
              id="modal-prevent-closing"
              ref="my-modal"
              no-close-on-backdrop
              button-size="sm"
              title="Ajouter une pièce jointe"
              ok-title="Ajouter"
              cancel-title="Fermer"
              cancel-variant="secondary"
              ok-variant="success"
              @show="clearPieceForm"
              @ok="handleOk"
            >
              <validation-observer ref="accountFilesRules">
                <form @submit.prevent="handleSubmitModal">

                  <b-form-group
                    label="Nom du document"
                    label-for="name_document"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="document"
                      rules="required"
                    >
                      <b-form-input
                        id="name_document"
                        v-model="piece.name_document"
                        size="sm"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Nom du document"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group
                    label="Pièce justificative"
                    label-for="fichier"
                    label-size="sm"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="fichier"
                      rules="required"
                    >
                      <b-form-file
                        id="fichier"
                        ref="file-input"
                        v-model="piece.fichier"
                        accept=".pdf"
                        size="sm"
                        placeholder="Choisir un fichier"
                        @change="handleImageSelected"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </form>
              </validation-observer>

            </b-modal>
          </b-row>
        </b-col>

      </b-row>
      <b-row v-else>
        <b-card
          no-body
          style="width: 100%;height: 40vh;bottom: 0;overflow-y:scroll"
        >
          <b-card-body style="display:flex;flex-direction:column">
            <div
              v-for="reponse_expertise, index of expertise.reponse_expertises"
              :key="index"
            >
              <div
                v-if="reponse_expertise.expert_id == user.id"
                class="d-flex flex-row justify-content-start"
              >
                <div
                  v-b-tooltip.hover.bottom="'Moi'"
                  style="padding:20px;border-radius:50%;background-color: #f5f6f7;"
                >
                  AB
                </div>
                <div v-b-tooltip.hover.bottom="'Envoyé par moi à ' + getTime(reponse_expertise.created_at)">
                  <p
                    class="small p-1 ms-3 mb-1 rounded-4"
                    style="background-color: #f5f6f7;"
                  >
                    {{ reponse_expertise.description }}
                  </p>
                  <!-- <p class="small ms-3 mb-3 rounded-4 text-muted">
                  00:13
                </p> -->
                </div>
              </div>
              <div
                v-else
                class="d-flex flex-row justify-content-end"
              >
                <div
                  v-if="reponse_expertise.expert === null"
                  v-b-tooltip.hover.bottom="'Envoyé par '+ 'le demandeur' +' à ' + getTime(reponse_expertise.created_at)"
                >
                  <p class="small p-1 me-3 mb-1 text-white rounded-4 bg-primary">{{ reponse_expertise.description }}
                  </p>
                  <!-- <p class="small me-3 mb-3 rounded-4 text-muted d-flex justify-content-end">
                  00:15
                </p> -->
                </div>
                <div
                  v-else
                  v-b-tooltip.hover.bottom="'Envoyé par ' + reponse_expertise.expert !== null ? reponse_expertise.expert.user.username : 'le demandeur' + ' à ' + getTime(reponse_expertise.created_at)"
                >
                  <p class="small p-1 me-3 mb-1 text-white rounded-4 bg-primary">{{ reponse_expertise.description }}
                  </p>
                  <!-- <p class="small me-3 mb-3 rounded-4 text-muted d-flex justify-content-end">
                  00:15
                </p> -->
                </div>
                <div
                  v-b-tooltip.hover.bottom="'Expert test: expert juridique'"
                  class="small p-1 me-3 mb-1 text-white rounded-4 bg-primary"
                  style="padding:20px;border-radius:50%;"
                >
                  AB
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
        <b-card
          no-body
          style="width: 100%; position:relative;bottom: 1vh;"
        >
          <b-card-body>
            <validation-observer ref="commentsRules">
              <b-form
                class="chat-app-form"
                @submit.prevent="sendMessage"
              >
                <validation-provider
                  name="reponse"
                  rules="required"
                  style="width: 75vw;"
                >
                  <b-input-group
                    class="input-group-merge form-send-message mr-1"
                    style="width: 100%;"
                  >
                    <b-form-input
                      v-model="form.description"
                      placeholder="Enter your message"
                    />
                  </b-input-group>
                </validation-provider>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-modal.validation-finale
                  variant="primary"
                  type="submit"
                  size="sm"
                  :disabled="expertise.statut === 'Fermée'"
                  class="float-right mb-1"
                >
                  Send
                </b-button>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
// eslint-disable-next-line import/no-cycle
import store from '@/store'

import {
  onBeforeMount, reactive, ref,
} from '@vue/composition-api'
import {
  BRow, BCol, BFormFile, BCard, BInputGroup, BBadge, BTable, BFormInput, BFormGroup, BButton, BCardBody, VBTooltip, BListGroup, BListGroupItem, BModal, BForm,
} from 'bootstrap-vue'

// eslint-disable-next-line import/no-cycle
import axiosClient from '@/helpers/axios'
// eslint-disable-next-line import/no-cycle
import useSocieteTransporteurs from '@/services/societe/societeTransporteurService'
// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/services/upload/useImageUpload'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BInputGroup,
    BButton,
    BCardBody,
    BBadge,
    BFormInput,
    BFormFile,
    BListGroup,
    BFormGroup,
    BListGroupItem,
    BModal,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      enChat: false,
    }
  },
  setup() {
    // Déclaration des variables et methodes importées
    const {
      imageUrl, imageFile, handleImageSelected, uploadFile, fileName, uploadExpertiseFile, fil, deleteFile,
    } = useImageUpload()
    const {
      findExpertiseById, deleteFileData, commentaire, getAllCommentByExpertises, sendExpertiseFiledata, commentaires, createCommentaire, errors, expertise, changeStatusExpertise, offreSuccess,
    } = useSocieteTransporteurs()
    const fields = [
      { key: 'document_type.label', label: 'Pièce', sortable: true },
      { key: 'status', label: 'Etat', sortable: true },
      { key: 'numero_piece', label: 'N°Pièce', sortable: true },
      { key: 'commentaire', label: 'Commentaire', sortable: true },
      { key: 'actions', label: 'Actions', sortable: true },
    ]
    const submittedNames = []

    const fields1 = reactive([
      { key: 'name_document', label: 'Nom document' },
      { key: 'actions' },
    ])
    const commentaireForm = reactive({
      commentaire: '',
    })
    const piece = ref({})
    const pieceJointe = reactive({
      name_document: '',
      nom_du_fichier: '',
      expertise_id: '',

    })
    const form = reactive({
      expertise_id: expertise.value.id,
      reponse_a: null,
      expert_id: JSON.parse(store.state.authStore.user).id,
      description: '',
    })
    const items = ref([])
    const user = ref([])
    const clearPiece = () => {
      form.status = ''
      form.email = null
      form.commentaire = ''
      form.id = null
    }
    const PDFURL = ref('')
    const getPdfFile = item => {
      PDFURL.value = ''
      PDFURL.value = item.pdf_url
      form.id = item.id
    }
    // Fin de la déclaration des variables et méthodes importées

    async function storeCommentaire() {
      form.expertise_id = expertise.value.id
      await createCommentaire({ ...form })
      if (offreSuccess.value === true && offreSuccess) {
        form.description = null
      }
    }
    const getUser = async () => {
      const response = await axiosClient.get('/user')
      user.value = response.data
    }

    const clearPieceForm = () => {
      piece.value = {}
      imageUrl.value = ''
      fileName.value = ''
    }
    async function queuePurchase() {
      const data = new FormData()
      data.append('fichier', imageFile.value)
      await uploadExpertiseFile(data)
      piece.value.document = piece.value.name_document
      piece.value.fileName = fil.value
      piece.value.imageUrl = imageUrl.value
      pieceJointe.name_document = piece.value.name_document
      pieceJointe.nom_du_fichier = fil.value
      pieceJointe.expertise_id = expertise.value.id
      await sendExpertiseFiledata({ ...pieceJointe })
      clearPieceForm()
    }

    const getImage = data => {
      console.clear()
      imageUrl.value = data.item.document_url
    }

    const removeItem = async data => {
      // console.clear()
      await deleteFileData(data.item.id)
      await deleteFile(data.item.nom_du_fichier)
      const indexOfObject = expertise.value.piece_expertises.findIndex(object => object.nom_du_fichier === data.item.nom_du_fichier)
      expertise.value.piece_expertises.splice(indexOfObject, 1)
    }
    onBeforeMount(async () => {
      await findExpertiseById()
      await getUser()
      window.Echo.channel(`expertise-${expertise.value.id}`).listen('.reponse-expertise', async event => {
        items.value.push(event)
        await findExpertiseById()
      })
    })
    const changerStatusExpertise = async () => {
      await changeStatusExpertise(expertise.value.id, { statut: 'Fermée' })
    }
    const resolvePieceStatusVariant = status => {
      if (status === 'Envoyé') return 'warning'
      if (status === 'Validé') return 'success'
      if (status === 'Rejeté') return 'danger'
      return 'primary'
    }
    const resolveExpertiseStatusVariant = status => {
      if (status === 'Soumise') return 'primary'
      if (status === 'En Cours') return 'warning'
      if (status === 'Fermée') return 'success'

      return 'primary'
    }
    // Obtenir une piece
    const getState = state => {
      form.status = state
    }
    const sendComment = data => {
      console.log(data)
      clearPiece()
    }
    const formatter = value => value.toUpperCase()
    return {
      formatter,
      getState,
      PDFURL,
      clearPiece,
      getPdfFile,
      getAllCommentByExpertises,
      commentaires,
      changerStatusExpertise,
      resolveExpertiseStatusVariant,
      resolvePieceStatusVariant,
      fields,
      fields1,
      required,
      offreSuccess,
      sendExpertiseFiledata,
      findExpertiseById,
      storeCommentaire,
      handleImageSelected,
      uploadFile,
      fileName,
      uploadExpertiseFile,
      fil,
      deleteFile,
      expertise,
      user,
      clearPieceForm,
      queuePurchase,
      commentaire,
      items,
      form,
      removeItem,
      getUser,
      createCommentaire,
      errors,
      submittedNames,
      getImage,
      piece,
      imageUrl,
      commentaireForm,
      sendComment,
    }
  },
  methods: {
    handleValidateOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleValidateSubmitModal()
    },
    getTime(d) {
      // Depending on timezone, your results will vary
      const event = new Date(d)
      return event.toLocaleTimeString('it-IT')
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    async handleValidateSubmitModal() {
      // Exit when the form isn't valid
      if (this.commentaires.length === 0) {
        this.$refs.commentsRules.validate().then(async success => {
          if (success) {
            await this.changerStatusExpertise()
            if (this.offreSuccess) {
              // Hide the modal manually
              this.$nextTick(() => {
                this.$refs['my-modal'].toggle('#toggle-btn')
              })
              await this.findExpertiseById()
              await this.getAllCommentByExpertises()
            }
          }
        })
      } else {
        await this.changerStatusExpertise()
        if (this.offreSuccess) {
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['my-modal'].toggle('#toggle-btn')
          })
          await this.findExpertiseById()
          await this.getAllCommentByExpertises()
        }
      }
    },
    sendMessage() {
      this.$refs.commentsRules.validate().then(async success => {
        if (success) {
          // Push the name to submitted names
          await this.storeCommentaire()
          if (this.offreSuccess) {
            // await this.findExpertiseById()
          }
        }
      })
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.$refs.accountFilesRules.validate().then(success => {
        if (success) {
          // Push the name to submitted names
          this.queuePurchase(this.piece)
          this.$refs['file-input'].reset()
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['my-modal'].toggle('#toggle-btn')
          })
        }
      })
    },
    modeChat() {
      // eslint-disable-next-line no-unused-expressions
      this.enChat ? this.enChat = false : this.enChat = true
      this.items = []
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
.chat-app-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.progress_bar {

  display: flex;
  counter-reset: circle 0;
}

::-webkit-scrollbar {
  display: none;
}

.progress_bar li {
  list-style: none;
  margin-right: 55px;
  counter-increment: circle 1;
  display: flex;
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.progress_bar li::before {
  content: counter(circle);
  position: absolute;
  top: 30px;
  height: 40px;
  width: 40px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  border-radius: 50%;
  z-index: 10;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s;
}

.progress_bar li::after {
  content: '';
  position: absolute;
  height: 3px;
  width: 120px;
  background-color: #f6f6f6;
  top: 48px;
  right: 37px;
  cursor: pointer;
  transition: all 0.5s;
}

.progress_bar li:nth-child(1)::after {
  display: none;
}

.progress_bar li.active::before {
  background-color: #005aa4 !important;
  color: #f6f6f6 !important;
}

#chat2 .form-control {
  border-color: transparent;
}

#chat2 .form-control:focus {
  border-color: transparent;
  box-shadow: inset 0px 0px 0px 1px transparent;
}

.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
</style>
